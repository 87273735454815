.icon {
    width: 13px;
    height: 13px;
    fill: var(--whitebase);
    margin: 0;
    margin-right: 7px;
}

.containerCheckmark {
    display: flex;
    flex-direction: column;
}

.check {
    display: flex;
    align-items: center;
    justify-content: center;
}
