.root {
    display: block;
    text-align: center;
    font-weight: bold;
}

.root label:hover {
    background: #ffffff;
    color: #000000;
    cursor: pointer;
}

.root label {
    color: var(--black-base);
    cursor: pointer;
    margin: 0;
    display: inherit;
    width: 100%;
}

.fileImg table {
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.fileImg table:hover {
    background-color: #efefef;
}
.fileImg td:nth-child(1) {
    width: 80%;
}

.fileImg td:nth-child(2) {
    width: 10%;
}

.fileImg td:nth-child(3) {
    width: 10%;
    cursor: pointer;
}

.fileImg table {
    border: 1px solid #dee2e6;
}

.fileImg svg {
    width: 50px;
    height: 36px;
    margin-top: 4px;
    color: #3688be;
}

.fileImg table tr td a i {
    color: #3688be;
}

.fileImg table tr td i {
    color: var(--tertiary-700);
}
