.bottomText2 {
    font-size: 12px;
    text-align: center;
}
.itemWrap {
    padding: 40px 60px;
}

.titleWrap {
    display: flex;
    align-items: center;
}

.headerTitle {
    margin-bottom: 0;
    margin-left: 20px;
}

.backIconBtn {
    border: none !important;
    height: 100% !important;
    padding: 0 !important;
}

.backIcon {
    width: 40px;
    height: 40px;
}

.tabIcon {
    width: 20px;
}

.tabTitle {
    margin-left: 10px;
    font-size: 16px;
    color: #212121;
}

.tabsWrapper {
    overflow: auto;
}

.bodyWrap {
    padding: 10px 40px;
    background: #e9f4ff;
    margin-bottom: 10px;
    position: fixed;
    top: 15px;
    z-index: 4;
}
.bodyWrap::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #ebebeb;
}

.title {
    color: #212121;
    font-weight: bold;
    font-size: 24px;
    text-transform: capitalize;
    margin-bottom: 0;
}

.description {
    color: #212121;
    font-size: 16px;
    margin-bottom: 10px;
}

.bodyInfoWrap {
    display: flex;
    margin-bottom: 10px;
}

.bodyInfoItem {
    margin-right: 30px;
}

.bodyInfoItem__title {
    font-size: 14px;
    margin-bottom: 0;
}

.bodyInfoItem__value {
    color: #1e90ff;
}

.actionWrap {
    display: flex;
    margin-bottom: 10px;
}

.leftActionWrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.leftActionBtn {
    color: #1e90ff !important;
    border: 1px solid !important;
    padding: 5px 10px !important;
    height: 40px !important;
    width: 110px !important;
    background: transparent !important;
    margin-right: 20px;
}

.rightActionWrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.formBodyWrap {
    display: flex;
}

.dropzoneWrap {
    flex: 1;
}

.dropzoneItem {
    margin-right: 30px;
    padding: 40px;
    outline: none;
    border: 1px dashed #cfcfcf;
}

.dropzone__text {
    font-size: 16px;
    font-weight: bold;
    color: #212121;
}

.dropzone__btn {
    background: #1e90ff !important;
    border-radius: 4px !important;
    color: #fff !important;
}

.listFiles {
    flex: 1;
    margin-right: 30px;
}

.fileItem {
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 16px 24px 0 rgba(48, 49, 51, 0.1), 0 0 1px 0 rgba(48, 49, 51, 0.05);
    margin-bottom: 30px;
}

.fileItem__img {
    height: 80px;
    width: 80px;
    border-radius: 10px 0 0 10px;
}

.fileItem__content {
    flex: 1;
    margin-left: 40px;
}

.fileItem__contentText {
    margin-bottom: 0;
}

.fileItem__contentTextBold {
    font-weight: bold;
}

.fileItem__removeIconWrap {
    margin-right: 16px;
}

.fileItem_removeIconBtn {
    cursor: pointer;
    padding: 0 !important;
    border: 0 !important;
    height: auto !important;
}

.fileItem_removeIcon {
    width: 24px;
    height: 24px;
}

.baseBtn {
    line-height: 1.5715;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    border: 1px solid transparent;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-color: #d9d9d9;
    outline: 0;
    color: #fff;
    background-color: #ff349b;
    border-radius: 4px !important;
    padding: 7px 17px !important;
    height: auto !important;
    display: flex !important;
    align-items: center;
    font-weight: bold !important;
}
.rejectBtn {
    margin-right: 50px;
    background-color: #1e90ff;
}
button.rejectBtn:hover {
    background-color: #62b1ff;
}

.baseBtn:hover {
    background-color: var(--secondary-300);
    color: #fff;
    border-color: inherit;
}

.submitBtn {
    background-color: var(--secondary-400-base);
}

.saveBtn {
    margin-right: 50px;
    border-color: var(--success-500);
    background-color: var(--success-500);
    color: var(--whitebase);
}

.baseBtn:hover {
    background-color: var(--success-500);
    color: #fff;
    border-color: inherit;
}

.saveBtn:active {
    border-color: var(--tertiary-300);
    background-color: var(--tertiary-300);
    color: var(--whitebase);
}

.saveBtn:disabled {
    cursor: auto;
    color: var(--whitebase);
    border-color: var(--black-300);
    background-color: var(--black-300);
}

.submitBtn:disabled {
    cursor: auto;
    color: var(--whitebase);
    border-color: var(--black-300);
    background-color: var(--black-300);
}

.submitBtn:disabled .icon {
    fill: var(--black-300);
}

.signature {
    border-color: var(--black-base);
    background-color: var(--black-100);
    color: var(--black-700);
}

.signature:hover {
    border-color: var(--black-base);
    background-color: var(--black-100);
    color: var(--black-700);
}

.submitBtn:hover {
    background-color: var(--secondary-300);
}

.saveBtn:hover {
    background-color: var(--warning-300);
}

/* --tablet-widths */
/* @media (min-width: 660px) {
    .bodyWrap {
        width: 100%;
        min-width: 100%;
    }
} */

/* --desktop-widths */
/* @media (min-width: 1400px) {
    .bodyWrap {
        width: 1100px;
        min-width: 1100px;
    }

} */

/* --mobile-widths-only */
@media (max-width: 659px) {
    .bodyWrap {
        width: 100%;
        min-width: 100%;
        padding: 10px;
        top: 0;
    }

    .title {
        margin: 0;
        font-size: 14px;
    }

    .description {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .bodyInfoWrap {
        display: block;
    }

    .bodyInfoItem {
        margin-right: 0;
        width: 100% !important;
    }

    .oneLine > p {
        display: inline-block;
        margin: 0;
    }

    .bodyInfoItem > p {
        margin: 0;
    }

    .bodyInfoItem__title {
        font-size: 12px;
    }
    .bodyInfoItem__value {
        font-size: 12px;
    }

    .leftActionWrap {
        display: block;
    }
    .rightActionWrap {
        display: block;
    }
    .baseBtn {
        line-height: 1;
    }
}
