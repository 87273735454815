.root {
    position: relative;
}
.main {
    display: flex;
    justify-content: center;
    /* width: 100%;
    min-height: calc(100vh - 268px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    position: relative; */
}

.root > footer {
    margin-top: auto;
}

/* --desktop-widths */
@media (min-width: 1400px) {
    .main {
        grid-area: main;
        margin: 0;
        padding: 0 50px;
        margin-top: 20px;
    }

    /* .root {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-column-gap: 41px;
        grid-template-areas:
            "main";
    } */
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */
/* Portrait and Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .main {
        min-height: inherit;
    }
}
